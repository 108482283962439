import React from "react";
import { useParams } from "react-router-dom";
import "./resetPassword.css";

function ResetPassword() {
  let { token } = useParams();
  let link = "https://script.google.com/macros/s/AKfycbwkLZNkI-0svZ7kvhDfyvIM9l92zr0b4hlayGwulfJmv1mCxUgyyk_d-3mSVSOVXiKcqw/exec?action=forgotpassword&token=" + token;
    return (
      <div className="reset-password">
        <iframe className="reset-password__iframe" src={link}></iframe>
      </div>
    );
  }
  
  export default ResetPassword;