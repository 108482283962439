import { Link } from "react-router-dom";

import img2 from "../../assets/image02.png";

function Layout({ children }) {
  return (
    <div id="wrapper">
      <div id="main">
        <div className="inner flex flex-col min-h-screen">
          <header id="header">
            <div id="container13" className="container columns full screen">
              <div className="wrapper">
                <div className="inner">
                  <div>
                    <div id="image02" className="image">
                      <Link to="/" className="frame">
                        <img src={img2} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </header>
          {children}
          <footer id="footer">
            <div id="container10" className="container default full">
              <div className="wrapper">
                <div className="inner">
                  <ul id="privacy" className="links">
                    <li className="n01">
                      <a href="mailto:phill@formulastudio.xyz">Contact</a>
                    </li>
                    <li className="n03">
                      <Link to="/privacy-policy">Privacy policy</Link>
                    </li>
                    <li className="n04">
                      <Link to="/tc">Terms &amp; Conditions</Link>
                    </li>
                    <li className="n04">
                      <Link to="/google-disclosure">Google Disclosure</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="container15" className="container default full screen">
              <div className="wrapper">
                <div className="inner">
                  <ul id="links03" className="links">
                    <li className="n01">
                      <a href="https://workspace.google.com/marketplace/app/formula_studio/142258573752">
                        Formula Studio for Google sheets
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Layout;
