import React from "react";
import "../../tailwind.css";
import "../../index.css";

function UserSuccessfullyCreated() {
  return (
    <div className="bg-white ">
      <div className="py-24 px-6 sm:px-6 sm:py-48 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" >
          Your account has been successfully created!
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600" >
            You are ready to start using Formula Studio.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://workspace.google.com/marketplace/app/formula_studio/142258573752"
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" 
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSuccessfullyCreated;
