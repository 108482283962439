import React from "react";
import { useParams } from "react-router-dom";
import "./checkout.css";

function Checkout() {
    let { session_id } = useParams();
    let link = "https://script.google.com/macros/s/AKfycbwkLZNkI-0svZ7kvhDfyvIM9l92zr0b4hlayGwulfJmv1mCxUgyyk_d-3mSVSOVXiKcqw/exec?action=checkout&session_id="+session_id;
    return (
    <div className="checkout">
        <iframe className="checkout__iframe" src={link}></iframe>
    </div>
    );
}
  
  export default Checkout;