function GoogleDisclosure() {
  return (
    <section id="home-section" className="wrapper-container">
      <h1 style={{ textAlign: 'center' }} id="text04"><span style={{color: "#009e53"}}>Google Disclosure</span></h1>
      <p className="style2">
      Formula Studio use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.
      </p>
    </section>
  );
}

export default GoogleDisclosure;