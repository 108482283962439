import React from "react";
import "./createAccount.css";

function CreateAccount() {
    return (
      <div className="create-account">
        <iframe className="create-account__iframe" src="https://script.google.com/macros/s/AKfycbwkLZNkI-0svZ7kvhDfyvIM9l92zr0b4hlayGwulfJmv1mCxUgyyk_d-3mSVSOVXiKcqw/exec"></iframe>
      </div>
    );
  }
  
  export default CreateAccount;