import { Route, Routes } from 'react-router-dom'
import { Links } from './Components/utils/Links'
import Home from './Components/home/Home'
import PrivacyPolicy from './Components/privacyPolicy/PrivacyPolicy'
import TermsAndConditions from './Components/termsAndConditions/TermsAndConditions'
import GoogleDisclosure from './Components/googleDisclosure'
import CreateAccount from './Components/createAccount/CreateAccount'
import ResetPassword from './Components/resetPassword/resetPassword'
import Checkout from './Components/checkout/checkout'
import UserSuccessfullyCreated from './Components/userSuccessfullyCreated/UserSuccessfullyCreated'
import MainForm from './Components/unsubscribe/MainForm'
import AI from './Components/AI/AI'

import TagManager from 'react-gtm-module';

import Layout from './Components/Layout'
import "./output.css";
import { useEffect } from 'react'

const { home, privacyPolicy, termsAndConditions, googleDisclosure, createAccount, resetPassword, checkout, userSuccessfullyCreated, mainForm, ai  } = Links;
function App() {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-P49XPLG' });
  }, [])

  return (
    <div className="App">
      <div className='content'>
        <Layout>
          <Routes>
            <Route path={home.path} element={<Home />} />
            <Route path={privacyPolicy.path} element={<PrivacyPolicy />} />
            <Route path={termsAndConditions.path} element={<TermsAndConditions />} />
            <Route path={googleDisclosure.path} element={<GoogleDisclosure />} />
            <Route path={createAccount.path} element={<CreateAccount />} />
            <Route path={resetPassword.path} element={<ResetPassword />} />
            <Route path={checkout.path} element={<Checkout />} />
            <Route path={userSuccessfullyCreated.path} element={<UserSuccessfullyCreated />} />
            <Route path={mainForm.path} element={<MainForm />} />
            <Route path={ai.path} element={<AI />} />
          </Routes>
        </Layout>
      </div>
    </div>
  )
}

export default App;
