export const Links = {
  home: {
    name: "home",
    path: "/",    
  },
  privacyPolicy: {
    name: "Privacy Policy",
    path: "/privacy-policy",
  },
  termsAndConditions: {
    name: "Terms and Conditions",
    path: "/tc",
  },
  googleDisclosure: {
    name: 'Google Disclosure',
    path: '/google-disclosure'
  },
  createAccount: {
    name: 'Create Account',
    path: '/create-account'
  },
  resetPassword: {
    name: 'Reset Password',
    path: '/reset-password/:token'
  },
  checkout: {
    name: 'Checkout',
    path: '/checkout/:session_id'
  },
  userSuccessfullyCreated: {
    name: 'User successfully created',
    path: '/user/successful_creation'
  }, 
  mainForm: {
    name: 'Unsubscribe Form',
    path: '/UnsubscribeForm'
  }, 
  ai: {
    name: 'AI',
    path: '/ai'
  }, 
};

