import React from "react";

import img1 from '../../assets/image01.jpg'
import gif from '../../assets/Formula Studio.gif'

const Home = () => {
	return ( 
		<>
			<section id="home-section">
				<div id="container03" className="container columns full">
					<div className="wrapper">
						<div className="inner">
							<div>
								<div id="image01" className="image" data-position="center">
									{/* <a href="#home" className="frame deferred"><img src="{img1}" data-src={img1} alt="" /></a> */}
									<a href="#home" className="frame deferred"><img src={gif} data-src={img1} alt="" /></a>
									</div>
							</div>
							<div>
								<h1 id="text04"><s>Create</s> Code formulas in <span style={{color: "#009e53"}}>Google Sheets</span> with <em>ChatGPT</em></h1>
								<p id="text05">The same experience as programming in JS or Python, but in spreadsheets.</p>
								<ul id="buttons01" className="buttons">
									<li>
										<a href="https://www.formulastudio.xyz/create-account" className="button n01">Start Free Trial</a>
									</li>
								</ul>
								<p id="text32" className="style2">*Only $5.99 per month after free trial</p>
							</div>
						</div>
					</div>
				</div>
				<h2 id="text44">Features</h2>
				<div id="container05" className="container columns">
					<div className="wrapper">
						<div className="inner">
							<div>
								<h3 id="text01" className="style1">🤖 ChatGPT</h3>
								<p id="text03" className="style2">Create formulas using Artificial Intelligence</p>
							</div>
							<div>
								<h3 id="text20" className="style1">👉 Auto indentation</h3>
								<p id="text15" className="style2">Better visualization of the more complex formulas</p>
							</div>
							<div>
								<h3 id="text16" className="style1">👇 Auto dragging</h3>
								<p id="text17" className="style2">A new and lighter version of Arrayformula</p>
							</div>

						</div>
					</div>
				</div>
				<div id="container04" className="container columns">
					<div className="wrapper">
						<div className="inner">
							<div>
								<h3 id="text18">✔️ Shortcuts</h3>
								<p id="text19" className="style2">New commands to increase your productivity</p>
							</div>
							<div>
								<h3 id="text10" className="style1">🔎 Replace all</h3>
								<p id="text21" className="style2">Do you have to change the same thing many times? Do it with two clicks!</p>
							</div>
							<div>
								<h3 id="text12" className="style1">🌙 Dark mode</h3>
								<p id="text13" className="style2">Beautiful design to optimize the coding experience</p>
							</div>

						</div>
					</div>
				</div>
				<h2 id="text45">FAQ</h2>
				<div id="container07" className="container columns">
					<div className="wrapper">
						<div className="inner">
							<div>
								<h3 id="text25" className="style1">Can I build formulas from scratch?</h3>
								<p id="text26" className="style2">Yes, of course! If you grab a cell without a formula you will be able to build from scratch, plus the auto complete will help you to do it more easily.</p>
							</div>
							<div>
								<h3 id="text22" className="style1">Can I use named ranges?</h3>
								<p id="text28" className="style2">Yes, in fact, you can name them from the editor and replace them in the formula automatically.</p>
							</div>
						</div>
					</div>
				</div>
				<div id="container06" className="container columns">
					<div className="wrapper">
						<div className="inner">
							<div>
								<h3 id="text23" className="style1">Do I need to know how to code to use Formula Studio?</h3>
								<p id="text24" className="style2">Not at all, only basic formulas like =SUM(), but it is more useful for complex calculations.</p>
							</div>
							<div>
								<h3 id="text14" className="style1">How is it different from the current box in Google Sheets?</h3>
								<p id="text11" className="style2">With formula studio you can see a complex formula in a much clearer way, with indentation, in a much more robust design and with many features to increase your productivity. You can also &quot;run&quot; the formula as if it were a function and check the results without closing the editor.</p>
							</div>
						</div>
					</div>
				</div>
				<h2 id="text46">User Reviews</h2>
				<div id="container08" className="container columns">
					<div className="wrapper">
						<div className="inner">
							<div>
								<p id="text41">I have a spreadsheet with a lot of formulas and I needed a reliable code editor that would help me maintain my Excel spreadsheet. I looked into a ton of different options and finally found the one that is the best for me: Formula Stutdio. <span style={{color: " #009e53"}}><strong>It&#039;s easy to use and provides all the features you need to manage Google Sheets.</strong></span></p>
								<p id="text42">Nicholas C.</p>
							</div>
							<div>
								<p id="text34">I have been looking for an easier way to edit my Google Sheets. I felt the default editor was not user-friendly and made it hard to navigate my document. Luckily, I found Formula Studio! <span style={{color: "#009e53"}}><strong>It completely changed the way I work on my spreadsheets</strong></span>. It&#039;s easy to use with a clean design that makes editing simple and intuitive.</p>
								<p id="text35">Alen S.</p>
							</div>
						</div>
					</div>
				</div>
				<div id="container01" className="container default">
					<div className="wrapper">
						<div className="inner">
							<h2 id="text08" className="style3"><span className="p">Get the power of coding in Google sheets<br />with formula studio</span></h2>
							<p id="text39">Pay once and Launch as many as needed.</p>
							<ul id="buttons03" className="buttons">
								<li>
									<a href="https://www.formulastudio.xyz/create-account" className="button n01">Start Free Trial</a>
								</li>
							</ul>
							<p id="text06">*Only $5.99 per month after free trial</p>
						</div>
					</div>
				</div>
			</section>
			<section id="why-section">
				<h2 id="text33">Why should you consider FS?</h2>
				<div id="container11" className="container default">
					<div className="wrapper">
						<div className="inner">
							<h3 id="text07" className="style4">Increase your productivity</h3>
							<p id="text30">Editing or constructing a long and complex formula can often be very time consuming. With Formula Studio you can solve problems faster and reduce the weight of your files so they always work correctly.</p>
							<div id="list02" className="list"><ul><li><p>New shortcuts</p></li><li><p>Better visualization</p></li><li><p>Arrayformula &quot;values only</p></li></ul></div>
						</div>
					</div>
				</div>
			</section>
		</>		
	);
};
export default Home;